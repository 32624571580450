import styled from "styled-components";
import {media} from "../variables";
import Collapsible from "react-collapsible";
import {Button} from "../Elements/Button";

export const TextElement = styled.div`
  font-size: 18px;
  margin-top: 40px;
  margin-left: 40px;
`

export const BoldText = styled.div`
  font-size: 24px;
  font-weight: bold;
`

export const DescriptionText = styled.div`
  ${media.tablet`
        padding-left: 10px;
    `}
`

export const Container = styled.div`
    margin: 1rem 1rem;
    position: relative;

  @media (max-width: 1024px) {
    padding: 0 1rem;
  }
`;

export const ChapterHeading = styled.h2`
    font-size: 32px;
    font-weight: 700;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const RefImage = styled.img`
  width: 300px;
  margin: 0 auto;
  text-align: center;
  display: block;

  @media (max-width: 1024px) {
    width: 160px;
  }
`;

export const LineSVG = styled.svg`
  @media (max-width: 1024px) {
      display: none;
  }
`;

export const PromoContainer = styled(Container)`
    width: 1170px;
    padding: 0 10px;
    text-align: center;

    ${media.tablet`
      width: 100%;
      margin: 0;
      padding: 0 2px;
    `}
`;

export const CampSectionHeading = styled.h2`
    text-align: left;
    font-size: 36px;
    font-weight: 600;
    color: #333;
    margin-bottom: 40px;
    ${media.tablet`
        font-size: 30px;
        margin: 0;
    `}
`;

export const FeatureContainerSken = styled.div`
    display: grid;
    grid-template-columns: 5fr 5fr;
    margin: 60px 0;

  @media (max-width: 1124px) {
    justify-content: center;
    gap: 40px;
  }
  ${media.desktop`
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
  `}
`

export const BoxAreas = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-top: 20px;

  ${media.tablet`
    margin-bottom: 0;
    grid-template-columns: 1fr;
    margin: 20px 0;
  `}

`

export const Area = styled.div`
  font-size: 20px;
  font-weight: 400;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
  background-color: #ffffff;
  color: #333333;
`

export const AreaDescription = styled.p`
  margin-top: 20px;
  color: #5f676f !important;
  font-weight: 500;
  font-size: 16px;
`

export const AreaIcon = styled.div`
    font-size: 32px;
    height: 68px;
    width: 68px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 6px 6px 20px 0 rgb(0 0 0 / 20%);
    line-height: 68px;
    margin: 0 auto 20px auto;
`

export const FeatureContainerTop = styled.div`
    display: grid;
    grid-template-columns: 5fr 6fr;
    margin: 60px 0;

    font-size: 18px;

    h3 {
        color: #000000;
    }

    p {
        color: #000000;
    }
    @media (max-width: 1124px) {
    justify-content: center;
  }
    ${media.desktop`
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
  `}
`;

export const FeatureContainerBottom = styled.div`
    display: grid;
    font-size: 18px;

    &:first-of-type {
        grid-template-columns: 4fr 6fr;
        margin-bottom: 8rem;
    }

    grid-template-columns: 6fr 4fr;

    h3 {
        color: #000000;
    }

    p {
        color: #000000;
    }
    @media (max-width: 1124px) {
    justify-content: center;
  }
    ${media.desktop`
    display: flex;
    flex-wrap: wrap-reverse;
  `}
`;

export const FeatureTextContainer = styled.div`
    display: flex;
    position: relative;
    padding: 0 0 0 40px;
    margin-right: 0;
    flex-direction: column;
    justify-content: center;
    text-align: left;

     & > p {
            margin-right: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
     & > h3 {
            margin-top: 10px;
            margin-bottom: 10px;
            margin-right: 10px;
        }

  ${media.mobile`
   padding: 0 0 0 0;
   &  h3 {
    font-size: 18px;

  }
  & > p {
    font-size: 16px;

  }
    `};
`;

export const FeatureTextContainerUnit = styled.div`
    margin: 20px 0 10px;
   font-size: 16px;
`;

export const FeatureImageContainer = styled.div`
    position: relative;
    max-width: 690px;
  
  > img {
    border-radius: 4px;
  }
`;

export const Wrapper = styled.div`
  &:hover {
    cursor: pointer;
  }

  .faq {
    border-bottom: 2px solid #333333;
    background-color: #ffffff;
  }

  .faq-title {
    border: none;
    background: none;
    outline: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    
    font-size: 24px;
    font-weight: bold;
    padding: 24px;
    padding-right: 48px;
    padding-left: 16px;
    position: relative;
    
    ${media.mobile`
        font-size: 18px;
    `}

    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-left: 2px solid #000000;
      border-bottom: 2px solid #000000;
      position: absolute;
      top: 34px;
      right: 36px;
      transform: rotate(-45deg);
      transition: transform 0.3s ease-in-out;
    }

    &.active {
      &::after {
        transform: rotate(135deg);
      }
    }
  }

  .faq-content {
    padding: 0 16px 32px 16px;
    line-height: 26px;
    color: #333333;
    text-align: left;
    font-size: 16px;
  }
`;

export const TableContainer = styled.div`
  border-radius: 3px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 20px 60px 20px;
  justify-content: center;
  grid-gap: 10px;
  
  ${media.tablet`
    grid-template-columns: 1fr;
    margin: 30px auto 0 auto;
    grid-gap: 40px;
    justify-items: center;
`}
`;

export const PriceColumn = styled.div<{ isMobileVisible: boolean }>`
  width: 300px;
  flex-wrap: wrap;
  border: 1px solid #000000;
  background-color: #FFFFFF;
  border-radius: 4px;
  height: fit-content;
  margin: 0 20px;
  min-height: 500px;
  @media (max-width:1060px) {
    margin: 0 5px;
  }

  ${media.tablet`
      display: ${({isMobileVisible}) => !isMobileVisible ? 'none' : 'block'}
  `}
`;

export const PriceColumnHeader = styled.div`
  text-align: center;
  padding: 31px 30px 30px 30px;
  width: 100%;

  &:last-of-type {
    border-right: none;
  }
`;

export const PriceColumnFooter = styled.div`
  text-align: center;
  padding: 8px 0px 20px 0px;
  width: 100%;

  background: linear-gradient(112.1deg,rgba(32,38,57,1) 11.4%,rgba(63,76,119,1) 70.2% );
`;

export const PlanPrice = styled.div`
  color: #393C3D;
  color: #FFFFFF;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin: 17px auto 6px auto;
`;


export const PlanPriceComment = styled.div`
  color: #9B9FA2;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  margin-bottom: 12px;
`;

export const Motivation = styled.div`
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  color: #393C3D;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
  display: block;
`;

export const ProgramName = styled.div`
  color: #9B9FA2;
  font-size: 13px;
  width: 100%;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 18px;
  text-align: center;
  display: block;
`;


export const HeaderLine = styled.div<{ gradient: { start: string, end: string } }>`
  height: 6px;
  width: 240px;
  border-radius: 2px;
  ${({gradient: {start, end}}) => `background: linear-gradient(135deg, ${start}, ${end})`};
  box-shadow: 0 8px 40px -10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
`;


export const FeatureText = styled.div`
  color: #636668;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding-top: 10px;
`;

export const FeatureBox = styled.div`
  margin-top: 22px;
`;

export const FeatureGrid = styled.div`
  display: grid;
  min-height: 300px;
  vertical-align: middle;
  align-content: center;
`

export const FeatureBoxHeader = styled.div`
  color: #00070B;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
`;

export const FeatureBoxText = styled.div`
  color: #9B9FA2;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin-top: 4px;
`;

export const PerPeriod = styled.span`
    font-size: 18px;
    font-weight: 400;
`

export const CustomerPoint = styled.li`
  position: relative;
  list-style-type: none;
  margin: 17px 0;
  padding-left: 40px;
  font-size: 20px;
  
  &:before{
    content: "";
    width: 30px;
    height: 30px;
    background-image: url("/img/pages/customer.png");
    background-size: contain;
    position: absolute;
    left: 0;
    display: inline-flex;
    margin-right: 10px;
  } 
`

export const BannerContainer = styled.div`
  display: flex;
  margin: 20px auto 40px;
  padding: 20px 40px 20px 30px;
  color: #FFFFFF;
  max-width: 840px;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  background: linear-gradient(112.1deg,rgba(32,38,57,1) 11.4%,rgba(63,76,119,1) 70.2% );
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
  h3{
    font-size: 20px;
    margin: 0 0 10px 0;
    text-transform: uppercase;
  }
  p{
    font-size: 16px;
    margin: 0;
  }
  ${media.tablet`
    flex-direction: column;
  `}
`

export const TextWrapper = styled.div`
    text-align: left;
    flex: 1;
    ${media.tablet`
      text-align: center;
  `}
`

export const SubText = styled.div`
  font-size: 24px;
  color: #dddddd;
  font-weight: bold;
  text-align: center;
  
  ${media.mobile`
    font-size: 18px;
    padding: 20px;
  `}
`

export const TopGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
`

export const ReferenceGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 40px 0 40px 0;
  grid-gap: 20px;
  
  ${media.mobile`
    grid-template-columns: auto;
  `}
`

export const ScanRefImage = styled.img`
  width: 300px;
  margin: 0 auto;
  text-align: center;
  display: block;
`;


export const Btn = styled(Button)`
    height: 40px;
    background: #FFFFFF;
    text-transform: uppercase;
    color: rgba(63,76,119,1);
    ${media.tablet`
      margin: 20px 0 10px;
    `}
`