import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import {MainText} from "../components/MainComponents";
import {
    AbsolventCompanies,
    ChapterHeading,
    CompaniesSection,
    Container,
    FeatureSection,
    LineSVG,
    ProFeatureDetailsTwo,
    ProFeatureName, RefImage
} from "./common";
import {ReferenceGrid, ScanRefImage} from "../components/Skener/layout";

export const CoopPageENTemplate = ({ image, title, heading, description, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
   <div className="">
       <div
           className="full-width-image margin-top-0"
           style={{
               backgroundImage: `url("/img/layout/background-main.svg"), linear-gradient( 112.1deg,  rgba(32,38,57,1) 11.4%, rgba(63,76,119,1) 70.2% )`,
               backgroundPosition: `top left`,
               backgroundSize: "1920px",
               height: "350px",
           }}
       >
           <div
               style={{
                   display: 'flex',
                   height: '150px',
                   lineHeight: '1',
                   justifyContent: 'space-around',
                   alignItems: 'left',
                   flexDirection: 'column',
               }}
           >
               <MainText>{title}</MainText>
           </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h3 className="has-text-weight-semibold is-size-2">{heading}</h3>
            <p>{description}</p>
          </div>
        </div>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
        <CompaniesSection>
            <ChapterHeading>Penetration test customers</ChapterHeading>
            <ReferenceGrid>
                <ScanRefImage src={"/img/pages/cooperation-liferay.png"} alt={"Liferay logo"}/>
                <ScanRefImage src={"/img/pages/cooperation-deepnote.png"} alt={"Deepnote logo"}/>
                <ScanRefImage src={"/img/pages/cooperation-pap.png"} alt={"PAP logo"}/>
            </ReferenceGrid>

            <Container style={{margin: "80px 0 120px"}}>
                <ChapterHeading>References - penetration test</ChapterHeading>
                <ProFeatureDetailsTwo style={{marginTop: "30px"}}>
                    <ProFeatureName><RefImage src="/img/pages/cooperation-liferay.png"/></ProFeatureName>
                    <p>
                        One of our clients is Liferay - company which provides CRM solutions to large banking insitutions and companies such as Bosch or VMWare.
                    </p>
                    <FeatureSection>Penetration test target</FeatureSection>
                    <p>
                        Our work on the project <b>Pentest for software Liferay DXP 7.3</b> consisted of cybersecurity analysis of potential vulnerabilities in the application. This application was released to customers to update their Liferay DXP after the end of penetration test.
                    </p>
                    <FeatureSection>Scope of pentest and results</FeatureSection>
                    <p>
                        The scope of the project was set with the customer to <b>80 hours</b>, during which we tested the software from the security point of view. The result was a report, where we've outlined discovered vulnerabilities with recommendations, how to fix them.
                    </p>
                </ProFeatureDetailsTwo>
            </Container>
            <Container style={{margin: "80px 0 120px"}}>
              <ChapterHeading style={{marginBottom: "20px"}}>Companies we've reported vulnerabilities to</ChapterHeading>
              <AbsolventCompanies>
                  <img alt="Tesla" src="/img/vulnerabilities/tesla.png"/>
                  <img alt="Starbucks" src="/img/vulnerabilities/starbucks.png"/>
                  <img alt="Google" src="/img/vulnerabilities/google.png"/>
                  <img alt="Spotify" src="/img/vulnerabilities/spotify.png"/>
                  <LineSVG preserveAspectRatio="none" viewBox="0 0 1 1"
                           style={{width: "100%", marginTop: '0vw', height: '0vw', position: "relative"}}>
                  </LineSVG>
                  <img alt="GitLab" src="/img/vulnerabilities/gitlab.png"/>
                  <img alt="ESET" src="/img/vulnerabilities/eset.png"/>
                  <img alt="O2" src="/img/vulnerabilities/o2.png"/>
                  <img alt="T-Mobile" src="/img/vulnerabilities/tmobile.png"/>
                  <LineSVG preserveAspectRatio="none" viewBox="0 0 1 1"
                           style={{width: "100%", marginTop: '0vw', height: '0vw', position: "relative"}}>
                  </LineSVG>
                  <img alt="Rohlik" src="/img/vulnerabilities/rohlik.png"/>
                  <img alt="DameJidlo" src="/img/vulnerabilities/damejidlo.png"/>
                  <img alt="Mall.cz" src="/img/vulnerabilities/mall.png"/>
                  <img alt="CSFD" src="/img/vulnerabilities/csfd.png"/>
                  <LineSVG preserveAspectRatio="none" viewBox="0 0 1 1"
                           style={{width: "100%", marginTop: '0vw', height: '0vw', position: "relative"}}>
                  </LineSVG>
                  <img alt="Evernote" src="/img/vulnerabilities/evernote.png"/>
                  <img alt="Alza" src="/img/vulnerabilities/alza.png"/>
                  <img alt="DrMax" src="/img/vulnerabilities/drmax.png"/>
                  <img alt="CeskeDrahy" src="/img/vulnerabilities/cd.png"/>
                  <LineSVG preserveAspectRatio="none" viewBox="0 0 1 1"
                           style={{width: "100%", marginTop: '0vw', height: '0vw', position: "relative"}}>
                  </LineSVG>
                  <img alt="Bugcrowd" src="/img/vulnerabilities/bugcrowd.png"/>
                  <img alt="TREZOR" src="/img/vulnerabilities/trezor.png"/>
                  <img alt="GoPay" src="/img/vulnerabilities/gopay.png"/>
              </AbsolventCompanies>
          </Container>
        </CompaniesSection>
      </div>
    </section>
   </div>
  )
}

CoopPageENTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const CoopPageEN = ({ data }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <CoopPageENTemplate
        contentComponent={HTMLContent}
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        content={post.html}
      />
      <div style={{marginBottom: "100px"}}/>
    </Layout>
  )
}

CoopPageEN.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CoopPageEN

export const coopPageENQuery = graphql`
  query CoopPageEN($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
      }
    }
  }
`
